import api from '../api';
import apiNew from '../apiNew';

// eslint-disable-next-line import/prefer-default-export
export const fetchChecklistSyntheticData = async (params = {}) => {
  return apiNew.get('/checklist/synthetic', {params});
};

export const fetchChecklistAnalyticalData = async (params = {}, responseType = {arraybuffer: 'arraybuffer'}) => {
  return apiNew.get('/checklist/analytical', {params, ...responseType});
};
