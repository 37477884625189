import api from '../api';
import apiNew from '../apiNew';

const fetchHarvests = (params = {}) => {
  return apiNew.get(`/harvests`, {
    params: {
      ...params,
    },
  });
};

const fetchHarvestsbyId = (id, params = {}) => {
  return api.get(`/harvests/${id}`, {
    params: {
      ...params,
    },
  });
};

const fetchHarvestsFilter = (params = {}) => {
  return api.get(`/harvests/filter`, {
    params: {
      ...params,
    },
  });
};

const registerHarvests = (body = {}) => {
  return apiNew.post(`/harvests`, body);
};

const deleteHarvests = (id) => {
  return apiNew.delete(`/harvests/${id}`);
};

const changeHarvestsbyId = (id, body = {}) => {
  return apiNew.put(`/harvests/${id}`, body);
};

export {fetchHarvests, fetchHarvestsbyId, fetchHarvestsFilter, registerHarvests, deleteHarvests, changeHarvestsbyId};
